.worksOne {
    background-color:white;
    height: 100%;
    width: 100%;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
}

@media screen and (min-height:500px) and (min-width:736px){
.boldWork {
    position: absolute;
    bottom:1%;
    right:1%;
    font-size: 250px;
    color: rgba(219,141,108,0.5);
    font-weight: 700;
}
.meteoApp .blogApp {
    width: 100%;
    height: 23rem;
    margin-bottom: 2%;
    display: table;
    text-align: center;
    text-decoration: none;
}
.vertical-work{
    display: none;
    }
}

@media screen and (max-width:850px){
    .contribText {
        font-size: 0.75rem;
    }
    
      .contribStrong{
        font-size: 0.75rem;
    }
    
    .movieappDescription{
        font-size: 0.75rem;
    }
}

@media screen and (min-height:500px) and (max-width:736px){
    .boldWork {
    position: absolute;
    bottom:2%;
    right: 10%;
    font-size: 100px;
    color: rgba(219,141,108,0.5);
    font-weight: 700;
    
    }
    .vertical-work{
        display: none;
        }
        .meteoApp .blogApp {
            width: 100%;
            height: 13rem;
            margin-bottom: 40px;
            display: table;
            text-align: center;
            text-decoration: none;
        }


        
}

@media screen and (max-height:500px){
.boldWork {
    display: none;
}

.meteoApp .blogApp {
    width: 100%;
    height: 13rem;
    margin-bottom: 40px;
    display: table;
    text-align: center;
    text-decoration: none;
}

.vertical-work {
    text-align: center;
    writing-mode:vertical-rl; /* vertical text */
    line-height:2rem; /* center in div */
    transform:rotate(180deg); /* spin to orient as required */
    background-color: rgba(219,141,108,0.5);
    text-transform: uppercase;
    color:rgb(108,186,219);
  }

  .contribText {
    font-size: 0.70rem;
}

  .contribStrong{
    font-size: 0.70rem;
}

.movieappDescription{
    font-size: 0.70rem;
}
}

 @media screen and (min-height:500px) and (max-height:600px){
    .boldWork {
    position: absolute;
    bottom:2%;
    right: 10%;
    font-size: 100px;
    color: rgba(219,141,108,0.5);
    font-weight: 700;
    }
    
    .meteoApp .blogApp {
        width: 100%;
        height: 13rem;
        margin-bottom: 40px;
        display: table;
        text-align: center;
        text-decoration: none;
    }
    
    
      .contribText {
        font-size: 0.70rem;
    }
    
      .contribStrong{
        font-size: 0.70rem;
    }
    
    .movieappDescription{
        font-size: 0.70rem;
    }
    } 







.centeredWorkOne {
    position: absolute;
    top: 5%;
    left: 60%;
}

 .meteoApp{
    width: 100%;
 }


.blogApp{
    background-image: url('./images/movie-blog.png');
    background-repeat: no-repeat;
  background-size: contain;
}

.contribText{
    margin:0;
    color:gray;
}

.contribStrong{
   color: black;
}

.textDescription {
    bottom: 10%;
}

.gitLink:link {
    text-decoration: none;
    color:gray;
  }
  
  .gitLink:visited {
    text-decoration: none;
    color:gray;
  }
  
  .gitLink:hover, a:active {
    background-color: rgba(219,141,108,0.5);
    color:gray;
  }