.competences {
    background-color: #dcf5f5;
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    padding-right: 0px;
    padding-left: 0px;
}

@media (min-width:800px){
    .list-inline-item  {
        font-size: 5rem;
      }
      .list-inline-item img{
        width: 5rem;
      }
      .centeredIcons {
        position: absolute;
        top: 10%;
        left: -30%;
        width: 600px;
    }
}

.list-inline {
    list-style: none;
    padding-right: 30px;
    text-align: center;
  }
  
  .list-inline a{
    color: #1e1e1e;
  }
  .fab:hover{
    color:#d3bc67;
  }
  .list-inline-item .colored:hover, .fab:hover{
    color:#d3bc67;
  }

@media (max-width:800px){
.list-inline-item  {
    font-size: 3.5rem;
    }
    .list-inline-item img{
    width: 3.5rem;
    }
    .centeredIcons {
    position: absolute;
    top: 10%;
    left: -50%;
    width: 576px;
}
}

@media (max-width:500px)and (max-height:800px){
    .list-inline-item  {
        font-size: 2.5rem;
        }
        .list-inline-item img{
        width: 2.5rem;
        }
        .centeredIcons {
        position: absolute;
        top: 10%;
        left: -30%;
        width: 80%;
    }
    }

    @media (min-width:500px)and (max-width:650px){
        .list-inline-item  {
            font-size: 2.5rem;
            }
            .list-inline-item img{
            width: 2.5rem;
            }
            .centeredIcons {
            position: absolute;
            top: 10%;
            left: -30%;
            width: 80%;
        }
        }


.boldSkills {
    position: absolute;
    bottom:1%;
    right:1%;
    font-size: 250px;
    color: rgba(219,141,108,0.5);
    font-weight: 700;
}

@media screen and (min-height:500px) and (max-height:600px){
  .boldSkills {
  
  
      position: absolute;
      bottom:2%;
      right: 10%;
      font-size: 100px;
      color: rgba(219,141,108,0.5);
      font-weight: 700;
  
  
  }
}

@media screen and (min-height:500px) and (max-width:736px){
    .boldSkills {
    position: absolute;
    bottom:2%;
    right: 10%;
    font-size: 100px;
    color: rgba(219,141,108,0.5);
    font-weight: 700;
    }
    }

@media screen and (max-height:500px){
.boldSkills {
    display: none;
}

.vertical-skills {
    text-align: center;
    writing-mode:vertical-rl; /* vertical text */
    line-height:2rem; /* center in div */
    transform:rotate(180deg); /* spin to orient as required */
    background-color: rgba(219,141,108,0.5);
    text-transform: uppercase;
    color:rgb(108,186,219)
  }
}

@media screen and (min-height:500px){
    .vertical-skills{
        display: none;
        }
}