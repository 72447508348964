.worksOne {
background-color:white;
height: 100%;
width: 100%;
display: flex;
padding-right: 0px;
padding-left: 0px;
}

@media screen and (min-height:500px) and (min-width:736px){
.boldWork {
position: absolute;
bottom:1%;
right:1%;
font-size: 250px;
color: rgba(219,141,108,0.5);
font-weight: 700;
}
.meteoApp .weatherApp {
width: 100%;
height: 23rem;
margin-bottom: 2%;
display: table;
text-align: center;
text-decoration: none;
}
.vertical-work{
display: none;
}
}

@media screen and (max-width:850px){
.contribText {
    font-size: 0.80rem;
}

    .contribStrong{
    font-size: 0.80rem;
}

.appDescription{
    font-size: 0.80rem;
}
}

@media screen and (min-height:500px) and (max-width:736px){
.boldWork {
position: absolute;
bottom:2%;
right: 10%;
font-size: 100px;
color: rgba(219,141,108,0.5);
font-weight: 700;

}
.vertical-work{
    display: none;
    }
    .meteoApp .weatherApp {
        width: 100%;
        height: 13rem;
        margin-bottom: 40px;
        display: table;
        text-align: center;
        text-decoration: none;
    }


    
}

@media screen and (max-height:500px){
.boldWork {
display: none;
}

.meteoApp .weatherApp {
width: 100%;
height: 13rem;
margin-bottom: 40px;
display: table;
text-align: center;
text-decoration: none;
}

.vertical-work {
text-align: center;
writing-mode:vertical-rl; /* vertical text */
line-height:2rem; /* center in div */
transform:rotate(180deg); /* spin to orient as required */
background-color: rgba(219,141,108,0.5);
text-transform: uppercase;
color:rgb(108,186,219);
}

.contribText {
font-size: 0.80rem;
}

.contribStrong{
font-size: 0.80rem;
}

.appDescription{
font-size: 0.80rem;
}
}

.centeredWorkOne {
position: absolute;
top: 5%;
left: 60%;
}

.meteoApp{
width: 100%;
}

@media screen and (min-height:800px) and (min-width:800px){

}

.weatherApp{
background-image: url('./images/qn4Rd5Xspv.jpg');
background-repeat: no-repeat;
background-size: contain;
}

.contribText{
margin:0;
color:gray;
}

.contribStrong{
color: black;
}

.textDescription {
bottom: 10%;
}

.gitlink:link {
text-decoration: none;
color:gray;
}

.gitLink:visited {
text-decoration: none;
color:gray;
}

.gitLink:hover, a:active {
background-color: rgba(219,141,108,0.5);
color:gray;
}