.about {
    background-color:white;
    height: 100%;
    width: 100%;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
}

img {
    width: 105px;
    height: 90px;
}

.imageName{
    left: 10%;
    top: 5rem;
    position: fixed;
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 30px;
    
}

.textMotivation{
    left: 10%;
    top: 15rem;
    position: fixed;
    width: 80%;
    display: flex;
    justify-content: center;
    text-align: justify;
    padding: 30px;
    border: 1px solid rgb(125,185,153);
    border-image: linear-gradient(to right, rgba(108,219,141,0) 25%,rgba(108,219,141,1) 25%,rgba(108,219,141,1) 75%,rgba(108,219185,141,0) 75%);
    border-image-slice: 1;
    margin: 5rem 0 0 0;
} 

.nameProfile{
    margin-left: 5rem;
    border: 1px solid rgb(125,185,153);
    border-image: linear-gradient(to bottom, rgba(108,219,141,0) 25%,rgba(108,219,141,1) 25%,rgba(108,219,141,1) 75%,rgba(108,219,141,0) 75%);
    border-image-slice: 1;
    padding: 0 20px;
}

.name{
    margin-top:0.75rem;
}

@media (max-width: 575px) { 
    .nameProfile{
        border: 1px solid rgb(125,185,153);
        border-image: linear-gradient(to right, rgba(108,219,141,0) 25%,rgba(108,219,141,1) 25%,rgba(108,219,141,1) 75%,rgba(108,219185,141,0) 75%);
        border-image-slice: 1;
        padding: 0;
        margin: 0 0 0 0.65rem ;
    }
    .name{
        margin:0.5rem;
    }
    img{
        margin-top: 0.45rem;
        width:75px;
        height: 65px;
    }
    .textMotivation{
        margin: 1rem 0 0 0;
        border: 1px solid rgb(125,185,153);
        border-image: linear-gradient(to bottom, rgba(108,219,141,0) 25%,rgba(108,219,141,1) 25%,rgba(108,219,141,1) 75%,rgba(108,219,141,0) 75%);
        border-image-slice: 1;
    }
}



.boldAbout {
    position: absolute;
    bottom:1%;
    right:1%;
    font-size: 250px;
    color: rgba(219,141,108,0.5);
    font-weight: 700;
}


@media screen and (min-height:500px) and (max-height:600px){
    .boldAbout {
    
    
        position: absolute;
        bottom:2%;
        right: 10%;
        font-size: 100px;
        color: rgba(219,141,108,0.5);
        font-weight: 700;
    
    
    }
}

@media screen and (min-height:500px) and (max-width:736px){
    .boldAbout {
    position: absolute;
    bottom:2%;
    right: 10%;
    font-size: 100px;
    color: rgba(219,141,108,0.5);
    font-weight: 700;
    }
    }

@media screen and (max-height:500px){
.boldAbout {
    display: none;
}

.vertical-about {
    text-align: center;
    writing-mode:vertical-rl; /* vertical text */
    line-height:2rem; /* center in div */
    transform:rotate(180deg); /* spin to orient as required */
    background-color: rgba(219,141,108,0.5);
    text-transform: uppercase;
    color:rgb(108,186,219)
  }
}

@media screen and (min-height:500px){
    .vertical-about{
        display: none;
        }
}

@media screen and (max-width: 736px) and (min-width: 575px) and (max-height:500px)  { 
    .textMotivation{
        margin: 0;
        top:13rem;
    }
    .imageName {
        top:2rem;
    }
}

.fullstack{
    font-size: 1rem !important;
}

.fullstackAdress{
    font-size: 0.6rem !important;
}

.motivText{
    margin:0;
    color:gray;
}

.strongMotiv{
   color: black;
}